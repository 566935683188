<template>
  <div class="row">
    <div
      class="col-12 col-sm-6"
      :class="{
        'mb-3 mb-sm-0': hideDetails,
      }"
    >
      <InputDate
        :labeled="labeled"
        :dark="dark"
        :value="startDate"
        :label="startText"
        :disabledDates="disabledStart"
        :calendLang="calendLang"
        :hideDetails="hideDetails"
        :primary="primary"
        :disabled="startInputDisabled"
        @input="(v) => onChangeStartDate(v)"
        :disabled-date-message="disabledStartDateMessage"
      />
    </div>
    <div class="col-12 col-sm-6">
      <InputDate
        :labeled="labeled"
        :dark="dark"
        :value="endDate"
        :label="endText"
        :disabledDates="disabledEnd"
        :calendLang="calendLang"
        :hideDetails="hideDetails"
        :primary="primary"
        :disabled="endInputDisabled"
        @input="(v) => setEndDate(v)"
        :disabled-date-message="disabledEndDateMessage"
      />
    </div>
    <slot/>
  </div>
</template>

<script>
import InputDate from '@/components/ui/inputDateUi.vue';

export default {
  components: {
    InputDate,
  },
  props: {
    validationRules: {
      type: Object,
      default: () => {},
    },
    calendLang: {
      type: String,
      default: 'en',
    },
    startText: String,
    endText: String,
    startDate: Date,
    endDate: Date,
    defaultStartDate: Date,
    defaultEndDate: Date,
    crops: Array,
    current: String,
    disabledStartDateMessage: String,
    disabledEndDateMessage: String,
    dates: {
      type: Array,
      default: () => [],
    },
    dark: {
      type: Boolean,
      default: false,
    },
    labeled: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    primary: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>

</style>
