<script>

import Period from './periodComponent.vue';

export default {
  extends: Period,
  name: 'PeriodAlt2',
  props: {
    startLimitInterval: Object,
    postponementInterval: Object,
    nextYearStartLimitInterval: Object,
    onlyNextYearError: {
      type: String,
      default: '',
    },
    postponementSkipped: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      startDateLimit: 15,
    };
  },
  computed: {
    disabledStart() {
      // a custom function that returns true if the date is disabled
      // this can be used for wiring you own logic to disable a date if none
      // of the above conditions serve your purpose
      // this function should accept a date and return true if is disabled
      const customPredictor = (date) => !this.$moment(date).isAfter(this.postponementInterval.min, 'days');

      return this.postponementInterval.min.isAfter(this.startLimitInterval.max) ? {
        to: this.nextYearStartLimitInterval.min.toDate(),
        from: this.nextYearStartLimitInterval.max.isAfter(this.postponementInterval.max)
          ? this.postponementInterval.max.toDate() : this.nextYearStartLimitInterval.max.toDate(),
      } : {
        to: this.startLimitInterval.min.toDate(),
        ranges: [
          {
            from: this.startLimitInterval.max.toDate(),
            to: this.nextYearStartLimitInterval.min.toDate(),
          },
        ],
        from: this.nextYearStartLimitInterval.max.toDate(),
        customPredictor,
      };
    },
    disabledEnd() {
      return {};
    },
    startInputDisabled() {
      return false;
    },
    endInputDisabled() {
      return true;
    },
  },
  watch: {
    defaultStartDate: {
      immediate: true,
      handler(val) {
        this.onChangeStartDate(val);
      },
    },
  },
  methods: {
    /**
     * set startDate and update endDate
     */
    onChangeStartDate(newStartDate) {
      this.onChangeDefaultStartDate(newStartDate);
    },
    onChangeDefaultStartDate(defaultStartDate) {
      let startDate = defaultStartDate;

      if (this.postponementSkipped) {
        startDate = this.nextYearStartLimitInterval.min;
      } else if (this.postponementInterval.min.isAfter(defaultStartDate)) {
        startDate = this.$moment(this.postponementInterval.min).add(1, 'days');
      }

      const range = this.validationRules.min_contract_length - 1;
      const endDate = this.$moment(startDate).add(range, 'days').toDate();

      this.$emit('update:startDate', startDate);
      this.$emit('update:endDate', endDate);
    },
    setEndDate(endDate) {
      this.$emit('update:endDate', endDate);
    },
  },
};
</script>

<style>

</style>
